<template>
    <div id="user">
        <div class="navbar">
            <button>我的</button>
        </div>
        <div class="content">
            <div class="info" @click="editInfoPopup = true">
                <div class="header">
                    <img v-if="userInfo.photo" :src="userInfo.photo"/>
                    <div v-else class="defaultImg">
                        <i class="iconfont icon-zhanghao"></i>
                    </div>
                    <h4 v-if="userInfo.nick">{{userInfo.nick}}</h4>
                    <h4 v-else>完善信息</h4>
                </div>
                <div class="tips">
                    <div>
                        <h4>积分</h4>
                        <h4>{{integral}}</h4>
                    </div>
                </div>
            </div>

            <div class="order">
                <div class="header">
                    <h4><i class="iconfont icon-icon-"></i><span>订单</span></h4>
                    <button @click="orderShow(0)">全部订单 <i class="iconfont icon-xiayibu1"></i></button>
                </div>
                <div class="list">
                    <button @click="orderShow(1)">
                        <i class="iconfont icon-daifukuan3x">
                            <span v-if="toBePaid>0">{{toBePaid}}</span>
                        </i>
                        <p>待付款</p>
                    </button>
                    <button @click="orderShow(2)">
                        <i class="iconfont icon-daifahuo3x">
                            <span v-if="toBeDelivered>0">{{toBeDelivered}}</span>
                        </i>
                        <p>待发货</p>
                    </button>
                    <button @click="orderShow(3)">
                        <i class="iconfont icon-daishouhuo3x">
                            <span v-if="toBeReceived>0">{{toBeReceived}}</span>
                        </i>
                        <p>待收货</p>
                    </button>
                </div>
            </div>
            
            <div class="tabList">
                <ul>
                    <li @click="jump('adressManagement')">
                        <h4><i class="iconfont icon-dizhiguanli3x"></i>地址管理</h4>
                        <i class="iconfont icon-xiayibu1"></i>
                    </li>
                    <!-- <li>
                        <h4><i class="iconfont icon-guijijifen3x"></i>归集积分</h4>
                        <i class="iconfont icon-xiayibu1"></i>
                    </li> -->
                    <li>
                        <h4><i class="iconfont icon-lianxikefu3x"></i>联系客服</h4>
                        <i class="iconfont icon-xiayibu1"></i>
                    </li>
                    <!-- <li>
                        <h4><i class="iconfont icon-wodeshoucang3x"></i>我的收藏</h4>
                        <i class="iconfont icon-xiayibu1"></i>
                    </li> -->
                </ul>
            </div>
        </div>

        <van-popup v-model="orderPopup" class="orderPopup" :overlay="false">
            <div class="header">
                <button @click="orderPopup = false">
                    <i class="iconfont icon-fanhui1"></i>{{orderName()}}
                </button>
            </div>
            <div class="list" v-if="orderList">
                <van-list v-model="orderLoading" :finished="orderFinished" finished-text="没有更多了" @load="orderOnLoad">
                    <div class="card" v-for="(item, index) in orderList" :key="index" v-if="orderStatus == 0 && item.orderStatus !== 4"  @click.stop="jump('logistics',item.orderId)">
                        <div class="top" v-if="item.products[0]">
                            <img :src="item.products[0].listImg">
                            <div>
                                <h4>{{item.products[0].name}}</h4>
                                <!-- <h4>{{item.products[0].name}} {{item.orderId}}-{{item.orderStatus}}</h4> -->
                                <p>{{`￥${fixedPrice(item.products[0].cashPrice)} + 良豆 ${item.products[0].integral}`}}</p>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 1">
                            <div>订单待付款</div>
                            <div>
                                <button @click.stop="cancelOrder(item.orderId)">取消订单</button>
                                <button class="active" @click.stop="patment(item.orderId)">立即付款</button>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 2">
                            <div>订单待发货</div>
                            <div>
                                <button @click.stop="reminders">提醒发货</button>
                                <button @click.stop="refund" class="active" style="display:none">退款</button>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 3">
                            <div>订单待收货</div>
                            <div>
                                <button @click.stop="extendedReceipt">延长收货</button>
                                <button>查看物流</button>
                                <button @click.stop="confirmReceipt(item.orderId)" class="active">确认收货</button>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 5">
                            <div>订单已完成</div>
                            <div>
                                <button>查看物流</button>
                            </div>
                        </div>
                    </div>

                    <div class="card" v-for="(item, index) in orderList" :key="index" v-if="item.orderStatus == orderStatus" @click.stop="jump('logistics',item.orderId)">
                        <div class="top" v-if="item.products[0]">
                            <img :src="item.products[0].listImg">
                            <div>
                                <h4>{{item.products[0].name}}</h4>
                                <p>{{`￥${fixedPrice(item.products[0].cashPrice)} + 良豆 ${item.products[0].integral}`}}</p>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 1">
                            <div>订单待付款</div>
                            <div>
                                <button @click.stop="cancelOrder(item.orderId)">取消订单</button>
                                <button class="active" @click.stop="patment(item.orderId)">立即付款</button>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 2">
                            <div>订单待发货</div>
                            <div>
                                <button @click.stop="reminders">提醒发货</button>
                                <button @click.stop="refund" class="active" style="display:none">退款</button>
                            </div>
                        </div>
                        <div class="bottom" v-if="item.orderStatus == 3">
                            <div>订单待收货</div>
                            <div>
                                <button  @click.stop="extendedReceipt">延长收货</button>
                                <button>查看物流</button>
                                <button  @click.stop="confirmReceipt(item.orderId)" class="active">确认收货</button>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </van-popup>

        

        <van-popup v-model="editInfoPopup" class="editInfoPopup"  :overlay="false">
            <div class="header">
                <button @click="editInfoPopup = false"><i class="iconfont icon-fanhui1"></i>个人信息</button>
            </div>
            <div class="content">
                <van-cell is-link center >
                    <template #title>
                        <span class="custom-title">头像</span>
                    </template>

                    <template #default>
                        <van-uploader :after-read="afterRead">
                            <img v-if="userInfo.photo" :src="userInfo.photo" class="smallHeader">
                            <button v-else class="uploadImg"><i class="iconfont icon-haibao"></i></button>
                        </van-uploader>
                    </template>
                </van-cell>
                <van-cell is-link center @click="changeName">
                    <template #title>
                        <span class="custom-title">姓名</span>
                    </template>

                    <template #default>
                        <div>
                            {{userInfo.nick ? userInfo.nick : '设置姓名'}}
                        </div>
                    </template>
                </van-cell>
            </div>
        </van-popup>

        <van-popup v-model="editNamePopup" class="editNamePopup" :overlay="false">
            <div class="header">
                <span @click="confirmEditName(1)">取消</span>
                <h4>设置名字</h4>
                <span @click="confirmEditName(2)">完成</span>
            </div>
            <div class="content">
                <!-- <input type="text" v-model="editInfoData.nick"> -->
                <van-field v-model="editInfoData.nick" :autofocus="true" placeholder="请输入用户名" />
            </div>
        </van-popup>

        
        <van-popup v-model="h5Show" class="h5Show" :close-on-click-overlay="false">
            <div>请确认微信支付是否完成</div>
            <p @click="h5Jump(1)">已完成支付</p>
            <p @click="h5Jump(2)">取消支付</p>
        </van-popup>


        <Login :isLogin="isLogin" :popupShow.sync="isLogin"/>
    </div>
</template>

<script>
import {getauthinfo, getOrderList, upload, editInfo, update, getIntergal} from '../../api/user'
import Login from '../component/login'
import {getPayInfo, h5pay} from '../../api/wxjsapi'
export default {
    data () {
        return {
            isLogin: false,
            orderListData: { //订单列表入参
                index: 1,
                key: '',
                status: 0
            },
            orderPopup: false,
            orderStatus: 0,
            orderList: '', //订单列表
            toBePaid: 0, //待付款数量
            toBeDelivered: 0, //待发货数量
            toBeReceived: 0, //待收货数量
            userInfo: '', //个人信息
            editInfoPopup: false,
            editNamePopup: false,
            editInfoData: {
                photo: '',
                nick: ''
            },
            orderLoading: false,
            orderFinished: false,
            integral: 0,
            h5Show: false
        }
    },
    mounted () {
        /* const {orderState, integral} = this.$route.query;
        this.integral = integral;
        orderState && this.orderShow(orderState);
        this.toBePaid = 0;
        this.toBeDelivered = 0;
        this.toBeReceived = 0;
        this.getUserInfo();
        this.getOrder(1);
        this.getStatusOrder(); */
    },
    activated () {
        const {orderState, integral} = this.$route.query;
        // this.integral = localStorage.getItem('integral') || integral; 
        orderState > -1 && this.orderShow(orderState);
        this.orderLoading = false;
        this.orderFinished = false;
        this.orderList='';
        this.toBePaid = 0;
        this.toBeDelivered = 0;
        this.toBeReceived = 0;
        this.getUserInfo();
        this.getOrder(1);
        this.getStatusOrder();
        this.userIntergal();
    },
    components :{
        Login
    },
    computed: {
        orderName () {
            return () => {
                const {orderStatus} = this;
                switch (orderStatus) {
                    case 0: return '全部订单';
                    case 1: return '待付款';
                    case 2: return '待发货';
                    case 3: return '待收货';
                }
            }
        },
        fixedPrice () {
            return (price) => {
                return price.toFixed(2);
            }
        },
    },
    methods: {
        /* 获取用户登录信息 */
        async getUserInfo () {
            let res = await getauthinfo();
            console.log(res);
            const {code, data, msg} = res;
            if (code === 0) {
                this.isLogin = true;
            } else if (code === 1) {
                this.userInfo = data;
                console.log(this.userInfo);
                data.nick && (this.editInfoData.nick = data.nick);
            }
        },

        /* 获取用户积分 */
        async userIntergal () {
            const {uid} = this.$route.query;
            let arr = {uid};
            let res = await getIntergal(arr);
            this.integral = res;
        },

        /* 获取状态订单 */
        async getStatusOrder () {
            let arr1 = {index: 1, key: '', status:1};
            let toBePaidRes = await getOrderList(arr1);
            this.toBePaid = toBePaidRes.data.total;

            let arr2 = {index: 1, key: '', status:2};
            
            let toBeDeliveredRes = await getOrderList(arr2);
            this.toBeDelivered = toBeDeliveredRes.data.total;
            
            let arr3 = {index: 1, key: '', status:3};
            let toBeReceivedRes = await getOrderList(arr3);
            this.toBeReceived = toBeReceivedRes.data.total;
        },

        /* 获取订单 */
        async getOrder (num) {
            num && (this.orderListData.index = num);
            const {orderListData} = this;
            let res = await getOrderList(orderListData);
            const {code, data, msg} = res;
            if (code === 1) {
                orderListData.index > 1 ? 
                this.orderList.push(...data.orders) :
                this.orderList = data.orders;
                this.orderLoading = false;
                data.orders.length < 20 && (this.orderFinished = true);
            }
        },

        /* 订单列表加载更多 */
        orderOnLoad () {
            this.orderListData.index++;
            this.getOrder();
        },

        /* 查看对应订单 */
        orderShow (status) {
            this.orderPopup = true;
            this.orderStatus = status;
        },

        /* 跳转 */
        jump (name, orderId) {
            const {$router, $route} = this;
            const {appKey, credits, timestamp, sign, uid, integral} = $route.query;
            $router.push({name, query:{appKey, credits, timestamp, sign, uid, orderId, integral}});
        },

        /* 上传 */ 
        async afterRead (file) {
            console.log(file);
            let res = await upload({basedata: file.content, type: 'jpg'});
            if (res) {
                this.editInfoData.photo = res;
                let data = await editInfo(this.editInfoData);
                if (data.code === 1) {
                    this.$toast('修改成功');
                    this.getUserInfo();
                }
            }
        },

        /* 设置名称 */
        changeName (res) {
            this.editNamePopup = true;
        },

        /* 确认设置名称 */
        async confirmEditName (status) {
            if (status == 1) {
                this.editNamePopup = false;
                this.editInfoData.nick = this.userInfo.nick;
            } else {
                const {editInfoData} = this;
                if (editInfoData.nick) {
                    let res = await editInfo(editInfoData);
                    const {code,data,msg} = res;
                    if (code == 1)  {
                        this.$toast('修改成功');
                        this.editNamePopup = false;
                        this.getUserInfo();
                    } else {
                        this.$toast(msg);
                    }
                } else {
                    this.$toast('请输入名称');
                }
            }
        },

        /* 延长收货 */
        extendedReceipt () {
            this.$toast('以延长');
        },

        /* 催单 */
        reminders () {
            this.$toast('已催单');
        },

        /* 退款 */
        refund () {
            this.$toast('退款');
        },

        /* 取消订单 */
        async cancelOrder (orderId) {
            let updateData = {
                orderId,
                action: 'cancel'
            }
            let res = await update(updateData);
            const {code, msg} = res;
            if (code == 1) {
                this.$toast('订单已取消');
                this.getOrder(1);
            } else {
                this.$toast(msg);
            }
        },

        /* 确认收货 */
        async confirmReceipt (orderId) {
            let updateData = {
                orderId,
                action: 'confirm'
            }
            let res = await update(updateData);
            const {code, msg} = res;
            if (code == 1) {
                this.$toast('已确认收货');
                this.orderListData.index = 1;
                this.getOrder(1);
            } else {
                this.$toast(msg);
            }
        },

        /* 付款 */
        async patment (orderId) {
            let us = window.navigator.userAgent;
            if (us.indexOf('MicroMessenger') > -1) { //微信支付
                let arr = {
                    openId: localStorage.getItem('openid'),
                    orderId
                }
                let res = await getPayInfo(arr);
                console.log(res);
                const {data} = res;
                this.onBridgeReady(data);
            } else { //h5支付
                // this.$toast('h5支付');
                this.h5Payment(orderId);
            }
        },

        /* h5Payment */
        async h5Payment (orderId) {
            let res = await h5pay({orderId});
            const {code, data, msg} = res;
            if (code === 1) {
                this.h5Show = true;
                window.location.href = data;
            }
        },

        h5Jump (status) {
            if (status == 1) {
                this.orderPopup = false;
                this.orderShow(2);
            }
            this.h5Show = false;
        },

        onBridgeReady(res) {
            let _this = this;
            // const {appId, timeStamp, nonceStr, package, signType, paySign} = res;
            WeixinJSBridge.invoke('getBrandWCPayRequest', {
                appId: res.appId,
                //公众号名称，由商户传入     
                timeStamp: res.timeStamp,
                //时间戳，自1970年以来的秒数     
                nonceStr: res.nonceStr,
                //随机串     
                package:  res.package,
                signType:  res.signType,
                //微信签名方式：     
                paySign: res.paySign, //微信签名 
            },
            function(res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    this.orderPopup = false;
                    this.orderListData.index = 1;
                    this.getOrder(1);
                }
            });
        },
    }
}
</script>


<style lang="less" src="@/style/user/user.less">

</style>