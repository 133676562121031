<template>
    <div id="login">
        <van-popup v-model="isLogin" class="loginPopup" :close-on-click-overlay="false" @click-overlay="changeClose">
            <div class="title">
                <i class="iconfont icon-guanbi"  @click="changeClose"></i>
            </div>
            <div class="popupContent">
                <div class="phone">
                    <i class="iconfont icon-shurushoujihao3x"></i>
                    <input type="number" placeholder="输入手机号" v-model="loginData.phone">
                </div>
                <div class="code">
                    <i class="iconfont icon-shuruduanxinyanzhengma3x"></i>
                    <input type="number" placeholder="输入短信验证码" v-model="loginData.code">
                    <button v-if="time">{{num}}s</button>
                    <button v-else @click="getCode">获取验证码</button>
                </div>
            </div>
            <button class="confirmBtn" @click="confirmLogin">登录</button>
        </van-popup>
    </div>
</template>

<script>
import {phoneCode, phoneLogin} from '../../api/login'
export default {
    data () {
        return {
            loginPopup: true,
            time: false,
            num: 60,
            timer: 0, //计时器
            loginData: { //登录入参
                phone: '',
                code: ''
            },
            reg: /^1[345678]\d{9}$/,
            isDraw: true

        }
    },
    mounted () {
        
    },
    props: {
        isLogin: Boolean,
    },
    methods: {
        /* 关闭弹窗 */
        changeClose () {
            this.$emit('update:popupShow', false);
        },

        /* 获取验证码 */
        async getCode () {
            const {loginData, reg, $toast} = this;
            if (!reg.test(loginData.phone)) {
                $toast('请输入有效的手机号');
                return;
            }
            this.time = true;
            this.timer = setInterval(() => {
                if (this.num > 0) {
                    this.num--;
                } else {
                    clearInterval(this.timer);
                    this.time = false;
                    this.num = 60;
                }
            },1000);
            let res = await phoneCode({phone: loginData.phone});
            console.log(res);
        },


        /* 登录 */
        async confirmLogin () {
            const {loginData, reg, isDraw} = this;
            if (!reg.test(loginData.phone)) {
                this.$toast('请输入有效的手机号');
                return;
            }
            if (isDraw) {
                let res = await phoneLogin(loginData);
                console.log(res);
                const {code, data, msg} = res;
                this.isDraw = false;
                if (code === 1) {
                    localStorage.setItem('accessToken', data.accessToken);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    this.$toast('登录成功');
                    this.$emit('update:popupShow', false);
                } else {
                    this.$toast(msg);
                    this.isDraw = true;
                }
            
            }
        }

    }
}
</script>

<style lang="less" src="@/style/component/login.less">

</style>